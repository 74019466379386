import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';

// components
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import Article from '../components/article/article';
import ContactSection from "../components/contact/contact";

// images
import img_text_section from "../images/vector/vector-controllerfunktion.svg";


function Controllerfunktion() {
    return (
        <Layout>
            <Helmet>
                <title>Excendo Redovisning AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta property="og:title" content="Excendo Redovisning AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="http://www.excendo.se/" />
                <link rel="cannonical" href="http://www.excendo.se/" />
            </Helmet>

            <Navbar whitemode={true} />

            <section className="-mt-20">
                {/*-------------- hero section - START ---------------*/}
                <div className="w-screen bg-controllerfunktion-hero bg-center bg-no-repeat bg-cover">
                    <div className="custome_overlay flex justify-center items-center py-48">
                        <h1 className="text-4xl font-semibold text-white text-center">Controllerfunktion</h1>
                    </div>
                </div>
                {/*-------------- hero section - END ---------------*/}
            </section>



            <section>
                {/*-------------- text section - START ---------------*/}
                <Article
                    image={img_text_section}
                    image_alt=""
                    heading="Controllerfunktion"
                    title="En resurs för ledningen"
                >
                    <p>
                        Vi arbetar med företag som kanske redan har en 
                        ekonomiavdelning men som också har en styrelse och vd som 
                        kräver mer än en avstämd resultat- och 
                        balansrapport vid månadsslutet.
                        <br/><br/>
                        Vi kommer in och tar det övergripande ansvaret för rapportering 
                        mot styrelse, verkställande direktör och andra 
                        nyckelpersoner för att förbättra och utveckla 
                        företagets processer och ekonomiska styrning.
                        <br/><br/>
                        Arbetet skräddarsys efter Era behov inom allt vad gäller ekonomistyrning 
                        och vad det innebär med finansiell rapportering, 
                        kalkyleringar, budget och uppföljning, resultatanalyser m.m.
                        <br/><br/>
                        Kontakta oss för mer information.
                    </p>
                </Article>
                {/*-------------- text section - END ---------------*/}
            </section>


            <div className="container mx-auto px-4 xl:px-24">
                <hr className="border-1 border-lightgray-excendo"></hr>
            </div>



            <section className="my-32">
                {/*-------------- other services - START ---------------*/}
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
                        <div className="px-8 md:px-0">
                            <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-excendo shadow-2xl">
                                <svg className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor" >
                                    <path stroke="currentColor" stroke-width="2" d="M18.4146+18.7591L1.61672+18.7591L1.61672+2.35182" fill="none" stroke-linecap="round" opacity="1" stroke-linejoin="round"/>
                                    <path d="M9.82035+19.1497L7.47646+19.1497L7.47646+8.99285L9.82035+8.99285L9.82035+19.1497ZM6.69516+19.1497L4.35127+19.1497L4.35127+11.7274L6.69516+11.7274L6.69516+19.1497ZM3.56997+19.1497L1.22607+19.1497L1.22607+14.0713L3.56997+14.0713L3.56997+19.1497ZM14.7035+6.25831C13.5186+6.25831+12.5549+5.29458+12.5549+4.10974C12.5549+2.9249+13.5186+1.96117+14.7035+1.96117C15.8883+1.96117+16.852+2.9249+16.852+4.10974C16.852+5.29458+15.8883+6.25831+14.7035+6.25831ZM16.6567+6.64896L12.7502+6.64896C11.5654+6.64896+10.6017+7.61269+10.6017+8.79753L10.6017+13.29C10.6017+13.7213+10.9521+14.0713+11.383+14.0713C11.8138+14.0713+12.1642+13.7213+12.1642+13.29L12.1642+9.95815C12.1642+9.85228+12.2537+9.76282+12.3596+9.76282C12.4654+9.76282+12.5549+9.85228+12.5549+9.95815L12.5549+18.095C12.5549+18.706+12.8811+19.1497+13.4925+19.1497C14.0714+19.1497+14.5081+18.6962+14.5081+18.095L14.5081+14.0713C14.5081+13.9635+14.5956+13.876+14.7035+13.876C14.8113+13.876+14.8988+13.9635+14.8988+14.0713L14.8988+18.209C14.9004+18.2114+14.9035+18.213+14.905+18.2153C14.9586+18.7556+15.3746+19.1497+15.9145+19.1497C16.5255+19.1497+16.852+18.706+16.852+18.095L16.852+10.0261C16.852+9.92025+16.9415+9.8308+17.0474+9.8308C17.1532+9.8308+17.2427+9.92025+17.2427+10.0261L17.2427+13.29C17.2427+13.7213+17.5931+14.0713+18.024+14.0713C18.4549+14.0713+18.8053+13.7213+18.8053+13.29L18.8053+8.79753C18.8053+7.61269+17.8416+6.64896+16.6567+6.64896Z" opacity="1" fill="currentColor"/>
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-blue-excendo font-medium mb-3">Rådgivning</h2>
                            <p className="font-light text-sm text-gray-500 mb-3">
                                Våra auktoriserade konsulter erbjuder expertrådgivning i det mesta kring företagande och mer avancerade bolagsärenden. 
                            </p>
                            <Link to="/radgivning" className="text-blue-excendo flex items-center hover:text-blue-600">
                                Läs mer
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>

                        <div className="px-8 md:px-0">
                            <div className="bg-red-100 rounded-full w-16 h-16 flex justify-center items-center text-orange-excendo shadow-2xl">
                                <svg className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor" >
                                    <path d="M19.0579+0.917844C18.6668+0.526798+18.1246+0.347649+17.5804+0.423739C15.0058+0.784927+8.97835+1.63059+3.34479+10.484L9.49075+16.629C18.3403+10.9964+19.1889+4.96796+19.551+2.39341C19.6271+1.84634+19.448+1.30793+19.0579+0.917844ZM10.4173+11.0012C9.61982+11.0012+8.97257+10.354+8.97257+9.55648C8.97257+8.75898+9.61982+8.11173+10.4173+8.11173C11.2148+8.11173+11.8621+8.75898+11.8621+9.55648C11.8621+10.354+11.2148+11.0012+10.4173+11.0012ZM14.27+7.14857C13.4725+7.14857+12.8252+6.50132+12.8252+5.70382C12.8252+4.90632+13.4725+4.25907+14.27+4.25907C15.0675+4.25907+15.7147+4.90632+15.7147+5.70382C15.7147+6.50132+15.0675+7.14857+14.27+7.14857Z" opacity="1" fill="currentColor"/>
                                    <path stroke="currentColor" stroke-width="2" d="M7.44499+15.2526C6.69275+16.0049+3.19357+16.7802+3.19357+16.7802C3.19357+16.7802+3.96892+13.281+4.72115+12.5288" fill="none" stroke-linecap="square" opacity="1" stroke-linejoin="miter"/>
                                    <path d="M4.51792+11.9644L1.5716+11.9644C1.06401+11.9644+0.610358+11.662+0.416762+11.1929C0.222202+10.7238+0.329114+10.1893+0.687412+9.83099L2.90173+7.52613C3.13385+7.28534+3.45459+7.14857+3.78881+7.14857L6.08307+7.14857L4.51792+11.9644ZM9.26826+19.6534C9.10549+19.6534+8.93982+19.6225+8.7809+19.5561C8.31184+19.3634+8.0094+18.9098+8.0094+18.4022L8.0094+15.2555L12.8252+13.8907L12.8252+16.3015C12.8252+16.6473+12.6798+16.9777+12.4236+17.2108L10.1101+19.3172C9.89432+19.5339+9.58707+19.6534+9.26826+19.6534Z" opacity="1" fill="currentColor"/>
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-orange-excendo font-medium mb-3">Starta bolag</h2>
                            <p className="font-light text-sm text-gray-500 mb-3">
                                Vi hjälper Dig starta företaget oavsett vilken företagsform Du väljer, enskild firma, handelsbolag, kommanditbolag eller aktiebolag.
                            </p>
                            <Link to="/starta-bolag" className="text-orange-excendo flex items-center hover:text-red-600">
                                Läs mer
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>
                        
                        <div className="px-8 md:px-0">
                            <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-excendo shadow-2xl">
                                <svg className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor" >
                                    <path stroke="currentColor" stroke-width="2" d="M2.21613+2.36034L2.21613+19.3603" fill="none" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
                                    <path d="M19.2161+14.3603L11.5981+14.3603L10.5981+12.3603L1.21613+12.3603L1.21613+1.36034L11.8341+1.36034L12.8341+3.36034L19.2161+3.36034L19.2161+14.3603Z" opacity="1" fill="currentColor"/>
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-blue-excendo font-medium mb-3">Delgivningsmottagare</h2>
                            <p className="font-light text-sm text-gray-500 mb-3">
                                Excendo tillhandahåller en särskild delgivningsmottagare/delgivningsbar person samt utför administrativa tjänster åt bolag som saknar behörig ställföreträdare bosatt i Sverige.
                            </p>
                            <Link to="/delgivningsmottagare" className="text-blue-excendo flex items-center hover:text-blue-600">
                                Läs mer
                                <svg className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>

                        
                        
                    </div> 
                </div> 
                {/*-------------- other services - END ---------------*/}
            </section>

            <ContactSection />
            <Footer />
            
        </Layout>
    );
}

export default Controllerfunktion;